body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App > section {
  min-height: 100vh;
}

.App .ant-layout-content .page {
  text-align: left;
  margin: 20px 0;
}

.App-link {
  color: #09d3ac;
}

.container {
  margin: 0 auto;
  padding: 0 $gutter;
  max-width: $container-width;
}

.App > .ant-layout > .ant-layout-content {
  width: 100%;
}

.navbar {
  height: $header-height;
}

.stats-card {
  border: none;
  background-color: $primary-color;
  border-radius: 16px;
  padding: 30px 10px 10px;

  &,
  & h3,
  & h4 {
    color: #fff;
  }

  & h3,
  & h4 {
    text-align: left;
    margin-bottom: 0;
  }

  & .stats-heading {
    font-size: 48px;
    font-weight: 700;
  }

  & .stats-heading.no-data {
    font-size: 30px;
  }

  & .stats-sub-heading {
    font-size: 18px;
    font-weight: 700;
  }

  &.public-centers {
    background-color: #f3747b;
  }

  &.mobile-caravans {
    background-color: #fe6262;
  }

  &.hotline {
    background-color: #ff6984;
    h3{
      font-size: 25px;
    }
  }

  .stats-background {
    position: absolute;
    right: 0;
    top: 0;
    width: 122px;
    border-top-right-radius: 16px;

    &.public-centers {
      color: #ffc278;
    }

    &.mobile-caravans {
      color: #fa0f00;
    }

    &.hotline {
      color: #be3386;
    }
  }

  .stats-icon {
    position: absolute;
    color: #fff;
    width: 28px;
    right: 40px;
    top: 12px;

    svg {
      width: 32px;
      height: 32px;
    }
  }
}

.badge {
  background-color: $primary-color;
  border-radius: 8px;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  padding: 0 9px;

  svg {
    margin-left: 5px;
    width: 16px;
  }
}

.blog-preview,
.about-preview {
  margin-top: 4rem;
  text-align: left;

  i {
    margin-right: 5px;
  }
}

.about-preview {
  i {
    color: $primary-color;
  }
}

.blog-preview {
  margin-bottom: 4rem;
}

h2.ant-typography,
.ant-typography h2 {
  color: $primary-color;
  font-weight: 700;
  font-size: 2rem;
}

.search-box {
  margin-top: 1rem;
  width: 80%;
  box-shadow: 2px 3px 6px -1px rgb(0, 0, 0, 0.4);
  border-radius: 4px;
}

.dropdown{
  width: 100% !important;
}
