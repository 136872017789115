.footer.ant-layout-footer {
  background-color: #f2f2f2;
  border-top: 14px solid $primary-color;
  width: 100%;
  position: static;
  bottom: 0;
  padding: 16px 0;
  margin-top: auto;
}

.made-by {
  align-items: stretch;
  display: flex;
  gap: 12px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & > .ant-typography {
    margin: 0;
  }
}

.footerLogo__image {
  max-height: 35px;
  max-width: 100%;

  &.main {
    max-height: 90px;
  }
}

.footer__links {
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
  margin-top: 16px;

  a {
    margin: 6px 12px 6px 0;
    text-decoration: underline;
    color: $footer-text-color;
  }
}

a.footer__donate {
  font-weight: 700;
  text-decoration-color: $green-color;

  span {
    color: $green-color;
  }
}

.main-logos {
  margin-right: auto;
  flex-wrap: wrap;

  & div {
    display: flex;
    gap: 12px;
  }

  & a {
    display: flex;
    align-items: center;
  }
}

.dev-logo {
  & a {
      height: 100%;
        display: flex;
        align-items: center;
      }
}

@media (max-width: $breakpoint-tablet) {
  .made-by,
  .footer__links {
    flex-direction: column;
    align-items: flex-start;
  }
}
