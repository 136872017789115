// =======================
// breakpoints
// =======================
$breakpoint-desktop: 991px;
$breakpoint-tablet: 767px;
$breakpoint-mobile: 575px;

// =======================
// layout
// =======================
$gutter: 1rem;
$container-width: 1280px;

// =======================
// header
// =======================
$header-height: 120px;

// =======================
// colors
// =======================
$primary-color: #be3386;
$primary-dark: #e43328;
$primary-light: #f0a167;

$secondary-dark: #be3386;
$secondary-main: #ff7648;
$secondary-light: #fe6262;

$text-color: #252525;
$green-color: #229445;

$footer-text-color: rgba(0,0,0,0.65);

// =======================
// Cards
// =======================
$card-colors: (
  '0': $secondary-main,
  '1': $secondary-dark,
  '2': $secondary-light,
);
$blog-card-radius: 0.5rem;
