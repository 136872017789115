.aboutTextBox {
  margin: 3rem 0;
  align-items: center;
  .aboutTextBoxCol {
    justify-content: center;
  }
  .aboutTextBox-paragraph {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: flex-start;
    color: #000000;
    text-align: left;
  }
}
.about-authors-col {
  display: inline-flex;
  justify-content: center;
  padding: 3rem 0;
}

.aboutLogo {
  width: 100%;
  max-width: 160px;
}