.hero-container {
  background-color: #fff;
}

.hero-body {
  text-align: left;
  background-color: #fff;
  padding-bottom: 30px;
  padding-top: 30px;

  .hero-img {
    height: 329px;
    & > div {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 100%;
    }
  }

  h3.hero-title {
    margin-top: 0;
  }
}

h2.hero-title {
  color: $primary-color;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 0;
}

.hero-btn-primary {
  margin-right: 16px;
  margin-top: 16px;
}
