.map-container {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 30%) 0px 4px 6px -1px;
  border-radius: 12px;
  width: 100%;
  height: 422px;
  background: grey;
}

%icon-base {
  height: 38px;
  margin: -38px 0px 0px -16px;
  width: 32px;
}

.user-pin-icon {
  @extend %icon-base;
  
  color: #4285f4;
}

%marker_base {
  margin: -39px 0 0 -17px;

  & svg {
    fill: #ee741b;
  }

  & svg:hover {
    cursor: pointer;
  }

  &.selected {
    >svg {
      fill: #000000;
    }
  }
}

.marker {
  @extend %marker_base;
}
