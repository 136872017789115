.blog-wrapper {
  min-height: 65vh;
  padding: 32px 0;
}

.blog-wrapper.loaded {
  text-align: left;
}

.load-more {
  text-align: center;
  margin-bottom: 2rem;

  button {
    font-weight: bold;
    font-size: 16px;
    border-width: 2px;
    border-radius: 8px;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.blog-item {
  margin-bottom: 3rem;
  cursor: pointer;

  &:hover {
    background-color: #fafafa;
  }

  .blog-image {
    height: 250px;
    padding-right: $gutter;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    @media (max-width: $breakpoint-tablet) {
      padding-right: 0;
      padding-bottom: $gutter;
    }
  }

  .blog-meta {
    display: flex;
    flex-direction: column;
    height: 100%;

    h3.ant-typography {
      color: $primary-color;
      font-size: 32px;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 10px;
      word-break: break-word;
      hyphens: auto;
    }

    div.ant-typography {
      font-size: 1rem;
      margin-bottom: 0;
    }

    a {
      color: initial;
      display: inline-block;
      font-size: 1rem;
      margin-left: auto;
      margin-top: auto;
      text-decoration: underline;
    }
  }
}

.blog-post-content {
  pre {
    white-space: pre-wrap;
  }

  .post-img {
    display: flex;
    justify-content: center;

    img {
      height: auto;
      max-width: 600px;
      width: 100%;
    }
  }
}
