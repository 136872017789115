.upload-btn {
  align-items: center;
  justify-content: center;
  color: $primary-color;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  white-space: nowrap;

  & .anticon {
    font-size: 28px;
  }
}
